import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { Spinner, VincentVega } from "../components";

export const NotFoundScene: React.FC = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Canvas
        camera={{ position: [0, 0, -3.5] }}
        style={{
          height: "100%",
          width: "100vw",
          zIndex: "1",
          background: "#1a2634",
          top: 0,
          position: "absolute",
        }}
      >
        <color attach="background" args={["#1a2634"]} />
        <fog attach="fog" args={["#1a2634"]} />

        <VincentVega />
        <ambientLight intensity={2} />

        <OrbitControls
          enableRotate={false}
          enablePan={false}
          enableZoom={false}
        />
      </Canvas>
    </Suspense>
  );
};
