import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

export const Contact: React.FC = () => {
  const [isAnimated, setIsAnimated] = useState(0);
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      setIsAnimated((prev) => prev + 1);
    }
  }, [inView]);

  return (
    <div className="relative z-10 text-white mb-20" id="contact" ref={ref}>
      <div className="mt-20 flex flex-row md:justify-normal justify-center">
        <div className="md:basis-1/5 sm:basis-0"></div>
        <div className="md:basis-1/4 sm:basis-full">
          <h1
            className={`font-bold text-white lg:text-[50px] sm:text-[50px] xs:text-[50px] text-[40px] lg:leading-[98px] mt-40 animate__animated ${
              inView && isAnimated === 1 ? "animate__zoomIn" : ""
            }`}
          >
            Contact
          </h1>
        </div>
      </div>
      <div className="md:basis-1/5 sm:basis-0"></div>
      <div className="md:basis-1/4 sm:basis-full"></div>
      <div
        className={`flex flex-wrap md:gap-72 gap-16 lg:justify-center md:justify-center sm:justify-center justify-center mb-8 mt-16 sm:mt-16 md:mt-16 animate__animated ${
          inView && isAnimated === 1 ? "animate__zoomIn" : ""
        }`}
      >
        <iframe
          className="px-8"
          title="Developer Hub Widget"
          src="https://discord.com/widget?id=1086332187219079238&theme=dark"
          width="350"
          height="500"
          sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
        ></iframe>
        <div className="flex flex-col items-center gap-28">
          <div className="flex flex-col items-center">
            <div className="bg-grey p-4 rounded-xl">
              <svg
                className="bi bi-discord h-[35px] w-[35px] fill-blue"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
              >
                <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z" />
              </svg>
            </div>
            <p className="text-xl font-bold my-2">Discord</p>
            <p className="text-base text-gray-400 text-center">
              @paradiesvogel7
              <br />
              @omega.hd
            </p>
          </div>
          <div className="flex flex-col items-center">
            <div className="bg-grey p-4 rounded-xl">
              <svg
                className="h-[35px] w-[35px] fill-blue"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
              </svg>
            </div>
            <p className="text-xl font-bold my-2">Ticket</p>
            <a
              href="https://discord.com/channels/1086332187219079238/1086332187881779406"
              rel="noreferrer"
              target="_blank"
            >
              <p className="text-base text-gray py-0.5 px-1 rounded text-center bg-[#264387]">
                # 🎫・support
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
