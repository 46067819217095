import React from "react";

export const Home: React.FC = () => {
  return (
    <div className="animate__fadeInLeft animate__animated absolute z-20 w-full mx-auto">
      <div className="font-bold absolute inset-0 top-[50px] lg:top-[25vh] md:top-[15vh] max-w-1xl mx-auto sm:px-16 px-4 flex flex-row items-start gap-5">
        <div className="ml-4 2xl:ml-40 xl:ml-20 lg:ml-20 md:ml-12">
          <h1 className="text-white lg:text-[60px] sm:text-[50px] xs:text-[50px] text-[40px] lg:leading-[98px]">
            Welcome to the
            <br />
            <span className="text-blue">Developer Hub</span>
          </h1>
          <p className="text-[#dfd9ff] font-medium lg:text-[19px] sm:text-[20px] xs:text-[20px] text-[20px] lg:leading-[40px] mt-2 mb-5">
            We're all about programming and looking for people{" "}
            <br className="hidden md:block" />
            who want to be part of our community
          </p>
          <a
            href="https://discord.gg/h4usmPQysz"
            rel="noreferrer"
            target="_blank"
          >
            <button className="bg-blue font-bold py-2 px-4 rounded inline-flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="currentColor"
                className="bi bi-discord w-4 h-4 mr-4"
                viewBox="0 0 16 16"
              >
                <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z" />
              </svg>
              <span>Join us on Discord</span>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};
