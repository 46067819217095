/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Author: Jan Bláha
Authors Webiste:  (https://jblaha.art/)
Source: https://sketchfab.com/3d-models/vincent-vega-426e2fe4a13c4cc78b280b4d76c72007
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
*/

import * as THREE from "three";
import React, { useRef, useEffect } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { useThree } from "@react-three/fiber";

type GLTFResult = GLTF & {
  nodes: {
    Cube015: THREE.SkinnedMesh;
    Cube015_1: THREE.SkinnedMesh;
    Cube015_2: THREE.SkinnedMesh;
    Cube015_3: THREE.SkinnedMesh;
    Cube015_4: THREE.SkinnedMesh;
    Cube015_5: THREE.SkinnedMesh;
    Cube015_6: THREE.SkinnedMesh;
    Cube015_7: THREE.SkinnedMesh;
    ["4_envelope"]: THREE.Mesh;
    ["4_coat"]: THREE.Mesh;
    root: THREE.Bone;
  };
  materials: {
    shared_hair_brown: THREE.MeshStandardMaterial;
    shared_skin: THREE.MeshStandardMaterial;
    shared_eye_black: THREE.MeshStandardMaterial;
    shared_eye_white: THREE.MeshStandardMaterial;
    ["4_suit"]: THREE.MeshStandardMaterial;
    ["4_shirt"]: THREE.MeshStandardMaterial;
    ["4_shoes"]: THREE.MeshStandardMaterial;
    shared_generic: THREE.MeshStandardMaterial;
    ["4_coat"]: THREE.MeshStandardMaterial;
  };
};

// type ActionName = "Animation";
// type GLTFActions = Record<ActionName, THREE.AnimationAction>;

export function VincentVega(props: JSX.IntrinsicElements["group"]) {
  const group = useRef<any>();
  const { nodes, materials, animations } = useGLTF(
    "models/404.glb"
  ) as GLTFResult;
  const { actions } = useAnimations(animations, group);

  const { width, height } = useThree((state) => state.viewport);

  useEffect(() => {
    actions["Animation"]!.play();
  });

  return (
    <group
      position={[
        width > 5.25 ? -1 : 0,
        width > 5.25 ? -0.8 : -2,
        width > 5.25 ? -2 : -2,
      ]}
      ref={group}
      {...props}
      castShadow
      receiveShadow
      dispose={null}
    >
      <group name="Scene">
        <group name="Armature004">
          <group name="4">
            <skinnedMesh
              name="Cube015"
              geometry={nodes.Cube015.geometry}
              material={materials.shared_hair_brown}
              skeleton={nodes.Cube015.skeleton}
            />
            <skinnedMesh
              name="Cube015_1"
              geometry={nodes.Cube015_1.geometry}
              material={materials.shared_skin}
              skeleton={nodes.Cube015_1.skeleton}
            />
            <skinnedMesh
              name="Cube015_2"
              geometry={nodes.Cube015_2.geometry}
              material={materials.shared_eye_black}
              skeleton={nodes.Cube015_2.skeleton}
            />
            <skinnedMesh
              name="Cube015_3"
              geometry={nodes.Cube015_3.geometry}
              material={materials.shared_eye_white}
              skeleton={nodes.Cube015_3.skeleton}
            />
            <skinnedMesh
              name="Cube015_4"
              geometry={nodes.Cube015_4.geometry}
              material={materials["4_suit"]}
              skeleton={nodes.Cube015_4.skeleton}
            />
            <skinnedMesh
              name="Cube015_5"
              geometry={nodes.Cube015_5.geometry}
              material={materials["4_shirt"]}
              skeleton={nodes.Cube015_5.skeleton}
            />
            <skinnedMesh
              name="Cube015_6"
              geometry={nodes.Cube015_6.geometry}
              material={materials["4_shoes"]}
              skeleton={nodes.Cube015_6.skeleton}
            />
            <skinnedMesh
              name="Cube015_7"
              geometry={nodes.Cube015_7.geometry}
              material={materials.shared_generic}
              skeleton={nodes.Cube015_7.skeleton}
            />
          </group>
          <primitive object={nodes.root} />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("models/404.glb");
