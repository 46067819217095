import React, { useEffect } from "react";
import { Spinner } from "../components";

export const Invite: React.FC = () => {
  useEffect(() => {
    window.location.href = "https://discord.gg/eM6AdvdBD2";
  }, []);

  return <Spinner />;
};
