import React, { Suspense, useState } from "react";
import { Canvas, extend } from "@react-three/fiber";
import { OrbitControls, Effects, Stars, PerformanceMonitor } from "@react-three/drei";
import { Planet } from "./Planet";
import * as THREE from "three";
import { Spinner } from "./Spinner";

import { UnrealBloomPass } from "three-stdlib";
import { OutputPass } from "three/examples/jsm/postprocessing/OutputPass";

extend({ UnrealBloomPass, OutputPass });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      unrealBloomPass: any;
      outputPass: any;
    }
  }
}

export const HomeScene: React.FC = () => {
  const [dpr, setDpr] = useState(1.5)
  
  return (
    <Suspense fallback={<Spinner />}>
      <Canvas
        dpr={dpr}
        gl={{ antialias: true }} 
        performance={{ min: 0.3 }}
        camera={{ position: [0, 0, -3.5] }}
        style={{
          height: "100vh",
          width: "100vw",
          zIndex: "1",
          background: "#1a2634",
          top: 0,
          position: "absolute",
        }}
      >
        <PerformanceMonitor onIncline={() => setDpr(2)} onDecline={() => setDpr(1)} ></PerformanceMonitor>

        <color attach="background" args={["#1a2634"]} />
        <fog attach="fog" args={["#1a2634"]} />

        <Planet />
        <Stars radius={500} depth={1} count={1000} factor={10} />

        <Effects disableGamma>
          <unrealBloomPass threshold={0.3} strength={0.3} radius={0.2} />
          <outputPass args={[THREE.ACESFilmicToneMapping]} />
        </Effects>

        <ambientLight intensity={1.4} />

        <OrbitControls
          enableRotate={false}
          enablePan={false}
          enableZoom={false}
        />
      </Canvas>
    </Suspense>
  );
};
