import React from "react";

export const NavBar: React.FC = () => {
  return (
    <header className="animate__animated animate__fadeInDown relative z-10 lg:px-16 px-6 bg-transparent flex flex-wrap items-center lg:py-0 py-2 w-ful top-[15px]">
      <div className="flex-1 flex justify-between items-center">
        <a href="/">
          <img
            height="70"
            width="70"
            src="/images/mstile-310x310.png"
            alt="logo"
          />
        </a>
      </div>
      <label htmlFor="menu-toggle" className="pointer-cursor lg:hidden block">
        <svg
          className="fill-current text-white"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <title>menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
        </svg>
      </label>
      <input className="hidden" type="checkbox" id="menu-toggle" />
      <div
        className="hidden lg:flex lg:items-center lg:w-auto w-full"
        id="menu"
      >
        <nav>
          <ul className="lg:flex items-center justify-between text-base text-white font-bold pt-4 lg:pt-0">
            <li className="text-blue">
              <a
                className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-blue"
                href="/"
              >
                Home
              </a>
            </li>
            <li>
              <a
                className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-blue"
                href="#about"
              >
                About
              </a>
            </li>
            <li>
              <a
                className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-blue"
                href="#team"
              >
                Team
              </a>
            </li>
            <li>
              <a
                className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-blue lg:mb-0 mb-2"
                href="#contact"
              >
                Contact
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

