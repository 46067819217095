import React from "react";
import { Footer, NavBar, NotFoundScene } from "../components";

export const NotFound: React.FC = () => {
  return (
    <>
      <NavBar />
      <div className="absolute z-10 w-full mx-auto animate__fadeInLeft animate__animated">
        <div className="font-bold absolute inset-0 top-[60px] md:top-[200px] max-w-1xl mx-auto sm:px-16 px-4 flex flex-row items-start gap-5 ml-4 lg:ml-44 md:ml-14">
          <div>
            <h1 className="text-white lg:text-[60px] sm:text-[50px] xs:text-[50px] text-[40px] lg:leading-[98px]  ">
              Hmmmm... <br /> Seems you're in the{" "}
              <br className="hidden md:block" />
              <span className="text-blue">Wrong</span> Place
            </h1>
            <p className="text-[#dfd9ff] font-medium lg:text-[20px] sm:text-[20px] xs:text-[20px] text-[16px] lg:leading-[40px] mt-2 text-white-100 mb-4">
              Please double check the URL or
              <br /> head back to the homepage.
            </p>
            <a href="/">
              <button className="bg-blue font-bold py-2 px-4 rounded inline-flex items-center">
                <span>Return to Home</span>
              </button>
            </a>
          </div>
        </div>
      </div>
      <NotFoundScene />
      <Footer homePage={false} />
    </>
  );
};
