import React, { useEffect, useRef } from "react";
import { Footer, HomeScene, NavBar } from "../components";
import { Home } from "./Home";
import { About } from "./About";
import { Team } from "./Team";
import { Contact } from "./Contact";
import "animate.css";

export const Root: React.FC = () => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [topSpace, setTopSpace] = React.useState(0);

  useEffect(() => {
    if (elementRef.current) {
      const elementHeight = elementRef.current.clientHeight;
      setTopSpace(elementHeight - 150);
    }
  }, []);

  return (
    <>
      <div className="bg-darkgrey h-[100vh]" ref={elementRef}>
        <NavBar />
        <Home />
        <HomeScene />
        <div
          className="shape-divider-home z-10 md:bottom-0"
          style={{ top: topSpace + "px" }}
        >
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
      <About />
      <Team />
      <div className="bg-darkgrey pb-[10px]">
        <div className="shape-divider-contact z-10">
          <svg
            className="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
        <Contact />
        <Footer homePage={true} />
      </div>
    </>
  );
};
