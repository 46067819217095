import React from "react";

export const Spinner: React.FC = () => {
  return (
    <div className="flex justify-center items-center h-[90vh]">
      <div className="relative z-10 inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] text-blue">
        <span className="absolute -m-px h-px w-px overflow-hidden whitespace-nowrap border-0 p-0 clip:rect(0,0,0,0)">
          Loading...
        </span>
      </div>
    </div>
  );
};
