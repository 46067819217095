import React, { useEffect, useState } from "react";
import { TeamCard, Spinner } from "../components";
import { useInView } from "react-intersection-observer";

interface teamMember {
  username: string;
  display_name: string;
  avatar_url: string;
}
interface teamData {
  online_members: number;
  total_members: number;
  moderators: teamMember[];
  admins: teamMember[];
  helpers: teamMember[];
  owner: teamMember[];
}

export const Team: React.FC = () => {
  const [teamData, setTeamData] = useState<teamData>();
  const [isAnimated, setIsAnimated] = useState(0);
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      setIsAnimated((prev) => prev + 1);
    }
  }, [inView]);

  useEffect(() => {
    fetch("https://api.developer-hub.com/server_info")
      .then((res) => res.json())
      .then((data) => {
        setTeamData(data);
        console.log(data);
      });
  }, []);

  return (
    <div className="relative z-10 text-white mb-10" id="team" ref={ref}>
      <div
        className={`animate__animated ${
          inView && isAnimated === 1 ? "animate__fadeInDown" : ""
        }`}
      >
        <div className="mt-20 flex flex-row md:justify-normal justify-center">
          <div className="md:basis-1/5 sm:basis-0"></div>
          <div className="md:basis-1/4">
            <h1 className="font-bold text-white lg:text-[50px] sm:text-[50px] xs:text-[50px] text-[40px] lg:leading-[98px] ">
              Team
            </h1>
          </div>
        </div>
        <div className="mt-2 flex flex-row md:justify-normal justify-center">
          <div className="md:basis-1/5 sm:basis-0"></div>
          <div className="lg:basis-1/2 xl:basis-1/2 2xl:basis-1/4 md:basis-1/2">
            <p className="text-[#dfd9ff] font-medium lg:text-[19px] sm:text-[20px] xs:text-[20px] text-[20px] mt-2 mb-5 md:px-0 px-6">
              The server team currently consists of five people. Paradiesvogel7
              and OMEGAHD are leading the server and taking the decisions. They
              are supported by three Moderators, who keep the server running and
              newly we have three Helpers who also support the team and the
              server.
            </p>
          </div>
        </div>
      </div>
      {teamData ? (
        <div>
          <div
            className={`md:mt-20 mt-10 flex flex-wrap gap-10 justify-center animate__animated ${
              inView && isAnimated === 1 ? "animate__zoomIn" : ""
            }`}
          >
            {teamData.owner.map((member) => (
              <TeamCard
                key={member.username}
                username={member.username}
                display_name={member.display_name}
                avatar_url={member.avatar_url}
                role="Owner"
                color="#000000"
              />
            ))}
            {teamData.admins.map((member) => (
              <TeamCard
                key={member.username}
                username={member.username}
                display_name={member.display_name}
                avatar_url={member.avatar_url}
                role="Admin"
                color="#e20665"
              />
            ))}
          </div>
          <div
            className={`md:mt-20 mt-10 flex flex-wrap gap-10 justify-center animate__animated ${
              inView && isAnimated === 1 ? "animate__zoomIn" : ""
            }`}
          >
            {teamData.moderators.map((member) => (
              <TeamCard
                key={member.username}
                username={member.username}
                display_name={member.display_name}
                avatar_url={member.avatar_url}
                role="Moderator"
                color="#f3c542"
              />
            ))}
          </div>
          <div
            className={`md:mt-20 mt-10 flex flex-wrap gap-10 justify-center animate__animated ${
              inView && isAnimated === 1 ? "animate__zoomIn" : ""
            }`}
          >
            {teamData.helpers.map((member) => (
              <TeamCard
                key={member.username}
                username={member.username}
                display_name={member.display_name}
                avatar_url={member.avatar_url}
                role="Helper"
                color="#bf6b34"
              />
            ))}
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};
