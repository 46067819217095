import React, { useEffect, useState } from "react";
import "react-vertical-timeline-component/style.min.css";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { useInView } from "react-intersection-observer";

const Icon = () => <></>;

export const About: React.FC = () => {
  const [isAnimated, setIsAnimated] = useState(0);
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      setIsAnimated((prev) => prev + 1);
    }
  }, [inView]);

  return (
    <div
      className="relative z-10 text-white mb-8 md:mb-8 lg:mb-40"
      id="about"
      ref={ref}
    >
      <div className="pt-20 flex flex-row md:justify-normal justify-center">
        <div className="md:basis-1/5 sm:basis-0"></div>
        <div className="md:basis-1/4">
          <h1
            className={`font-bold text-white lg:text-[50px] sm:text-[50px] xs:text-[50px] text-[40px] lg:leading-[98px] mb-15 animate__animated ${
              inView && isAnimated === 1 ? "animate__fadeInDown" : ""
            }`}
          >
            About
          </h1>
        </div>
      </div>
      <div className="mt-20 flex hidden flex-col sm:hidden md:block lg:block xl:block 2xl:block">
        <VerticalTimeline lineColor="#5865F2">
          <VerticalTimelineElement
            contentStyle={{
              background: "#081521",
              color: "#fff",
            }}
            contentArrowStyle={{ borderRight: "7px solid  #081521" }}
            date="17th March 2023"
            icon={<Icon />}
            iconStyle={{ background: "#1a2634", color: "#fff" }}
          >
            <div>
              <h3 className="text-white text-[24px] font-bold">
                Foundation of Developer Hub
              </h3>
              <p className="text-[#dfd9ff] text-[16px] font-semibold mt-5">
                At the beginning of the year, in March 2023, the Developer Hub
                Server was founded by Paradiesvogel7. The idea was to create a
                place where different developers can exchange knowledge, ask
                questions and talk to each other. The Owner, Paradiesvogel7,
                also developed a custom bot, supports the server to this day.
                There is also a second bot called "Developer Network". This
                bot's purpose is to create a global chat between different
                coding servers to connect more people together.
              </p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            contentStyle={{
              background: "#081521",
              color: "#fff",
            }}
            contentArrowStyle={{ borderRight: "7px solid  #081521" }}
            date="13th June 2023"
            icon={<Icon />}
            iconStyle={{ background: "#1a2634", color: "#fff" }}
          >
            <div>
              <h3 className="text-white text-[24px] font-bold">
                First Milestone
              </h3>
              <p className="text-[#dfd9ff] text-[16px] font-semibold mt-5">
                Almost exactly three months later, on June 13th 2023, the server
                reached the 100 member mark. The first milestone was achieved
                and the server kept growing. Many newcomers came, asked
                questions and quickly left the server. Others stayed and
                provided good conversations with their knowledge.
              </p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            contentStyle={{
              background: "#081521",
              color: "#fff",
            }}
            contentArrowStyle={{ borderRight: "7px solid  #081521" }}
            date="Future"
            icon={<Icon />}
            iconStyle={{ background: "#1a2634", color: "#fff" }}
          >
            <div>
              <h3 className="text-white text-[24px] font-bold">Our Future</h3>
              <p className="text-[#dfd9ff] text-[16px] font-semibold mt-5">
                The goal is to build a larger and growing community in the
                future. We want to provide a place for professionals and
                beginners to share their knowledge and learn new things.
              </p>
            </div>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
      <div
        className={`animate__animated ${
          inView && isAnimated === 1 ? "animate__fadeIn" : ""
        }`}
      >
        <div className="mt-2 flex flex-row md:justify-normal justify-center block sm:block md:hidden lg:hidden xl:hidden 2xl:hidden">
          <div className="md:basis-1/5 sm:basis-0"></div>
          <div className="md:basis-1/4">
            <p className="text-[#dfd9ff] font-medium lg:text-[19px] sm:text-[20px] xs:text-[20px] text-[20px] mt-2 mb-5 md:px-0 px-6">
              At the beginning of the year, in March 2023, the Developer Hub
              Server was founded by Paradiesvogel7. The idea was to create a
              place where different developers can exchange knowledge, ask
              questions and talk to each other. The Owner, Paradiesvogel7, also
              developed a custom bot, supports the server to this day. There is
              also a second bot called "Developer Network". This bot's purpose
              is to create a global chat between different coding servers to
              connect more people together.
            </p>
          </div>
        </div>
        <div className="mt-2 flex flex-row md:justify-normal justify-center block sm:block md:hidden lg:hidden xl:hidden 2xl:hidden">
          <div className="md:basis-1/5 sm:basis-0"></div>
          <div className="md:basis-1/4">
            <p className="text-[#dfd9ff] font-medium lg:text-[19px] sm:text-[20px] xs:text-[20px] text-[20px] mt-2 mb-5 md:px-0 px-6">
              Almost exactly three months later, on June 13th 2023, the server
              reached the 100 member mark. The first milestone was achieved and
              the server kept growing. Many newcomers came, asked questions and
              quickly left the server. Others stayed and provided good
              conversations with their knowledge.
            </p>
          </div>
        </div>
        <div className="mt-2 flex flex-row md:justify-normal justify-center block sm:block md:hidden lg:hidden xl:hidden 2xl:hidden">
          <div className="md:basis-1/5 sm:basis-0"></div>
          <div className="md:basis-1/4">
            <p className="text-[#dfd9ff] font-medium lg:text-[19px] sm:text-[20px] xs:text-[20px] text-[20px] mt-2 mb-5 md:px-0 px-6">
              In the Future the goal is to build a larger and growing community
              in the future. We want to provide a place for professionals and
              beginners to share their knowledge and learn new things.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
