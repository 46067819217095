import React from "react";

interface FooterProps {
  homePage: boolean;
}

export const Footer: React.FC<FooterProps> = ({ homePage }) => {
  return (
    <footer
      className={`${
        !homePage && "absolute bottom-[10px]"
      } bg-transparent text-center lg:text-left z-10 w-full flex flex-wrap items-center justify-center lg:justify-between lg:px-16 px-6`}
    >
      <div className="flex-1 flex items-center justify-center lg:justify-start mb-1 lg:mb-0">
        <a href="/" className="flex items-center">
          <img
            src="/images/apple-touch-icon-60x60.png"
            alt="logo"
            className="mr-2"
          />
          <span className="text-white">Developer Hub</span>
        </a>
      </div>
      <div className="p-4 text-center text-white">
        © 2023 Copyright - All Rights Reserved
      </div>
    </footer>
  );
};
