import React from "react";

interface TeamCardProps {
  username: string;
  display_name: string;
  avatar_url: string;
  role: string;
  color: string;
}

export const TeamCard: React.FC<TeamCardProps> = ({
  username,
  display_name,
  avatar_url,
  role,
  color,
}) => {
  return (
    <div className="w-full max-w-sm rounded-lg shadow bg-darkgrey border-blue border-2 lg:mx-0 md:mx-8 sm:mx-8 mx-8">
      <div className="flex flex-col items-center pb-10 mt-12">
        <img
          className="w-24 h-24 mb-3 rounded-full shadow-lg"
          src={avatar_url}
          alt="user-avatar"
        />
        <h5 className="mb-1 text-xl font-medium text-white">{display_name}</h5>
        <span className="text-sm text-gray-400">@{username}</span>
        <div className="flex mt-4 space-x-3 md:mt-6">
          <div className="inline-flex items-center px-4 py-2 text-sm font-medium text-center border rounded-lg bg-gray-800 text-white border-gray-600">
            <div
              style={{ backgroundColor: color }}
              className={`w-4 h-4 rounded-full mr-2`}
            ></div>
            {role}
          </div>
        </div>
      </div>
    </div>
  );
};
