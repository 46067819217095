/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Author: Meee (https://sketchfab.com/Meee)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/earth-hologram-87072288fb234226b9a3f02ae674a310
Title: Earth hologram
*/

import * as THREE from "three";
import React, { useRef, useEffect } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { useThree, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

type GLTFResult = GLTF & {
  nodes: {
    TERRE1_0: THREE.Mesh;
    Sphere002_0: THREE.Mesh;
    Circle643_0: THREE.Mesh;
    Circle455_0: THREE.Mesh;
    Circle_0: THREE.Mesh;
    Icosphere002_0: THREE.Mesh;
  };
  materials: {
    ["Material.005"]: THREE.MeshStandardMaterial;
    ["Material.006"]: THREE.MeshStandardMaterial;
    ["Material.004"]: THREE.MeshStandardMaterial;
    ["Material.007"]: THREE.MeshStandardMaterial;
    ["Material.010"]: THREE.MeshStandardMaterial;
    ["Material.008"]: THREE.MeshStandardMaterial;
  };
};

//type ActionName = "Take 01";
//type GLTFActions = Record<ActionName, THREE.AnimationAction>;

export function Planet(props: JSX.IntrinsicElements["group"]) {
  const group = useRef<any>();
  // with useLoader hook res is cached automatically
  const { nodes, materials, animations } = useLoader(GLTFLoader,
    "models/earth_hologram.glb"
  ) as GLTFResult;
  const { actions } = useAnimations(animations, group);

  const { width, height } = useThree((state) => state.viewport);

  useEffect(() => {
    actions["Take 01"]!.play();
  });
  return (
    <group
      position={[
        width > 5.17 ? -2.5 : 0,
        width > 5.17 ? 0 : -2.5,
        width > 5.17 ? 1.5 : 4,
      ]}
      ref={group}
      {...props}
      dispose={null}
    >
      <group name="Sketchfab_Scene">
        <group
          name="Sketchfab_model"
          rotation={[-Math.PI / 2, 0, 0]}
          scale={0.306}
        >
          <group name="Root">
            {/** Render Probleme deswegen beim planeten selbst Scale = 0*/}
            <group name="TERRE1" scale={0}>
              <mesh
                name="TERRE1_0"
                geometry={nodes.TERRE1_0.geometry}
                material={materials["Material.005"]}
              />
            </group>

            <group name="Sphere002" rotation={[0, 0, -3.015]} scale={0.965}>
              <mesh
                name="Sphere002_0"
                castShadow
                receiveShadow
                geometry={nodes.Sphere002_0.geometry}
                material={materials["Material.006"]}
              >
                <meshStandardMaterial
                  emissive={"blue"}
                  emissiveIntensity={2}
                  toneMapped={false}
                />
              </mesh>
            </group>

            <group name="Circle643" scale={4.787}>
              <mesh
                name="Circle643_0"
                castShadow
                receiveShadow
                geometry={nodes.Circle643_0.geometry}
                material={materials["Material.004"]}
              />
            </group>

            <group name="Circle455" scale={0.176}>
              <mesh
                name="Circle455_0"
                castShadow
                receiveShadow
                geometry={nodes.Circle455_0.geometry}
                material={materials["Material.007"]}
                morphTargetDictionary={nodes.Circle455_0.morphTargetDictionary}
                morphTargetInfluences={nodes.Circle455_0.morphTargetInfluences}
              />
            </group>

            <group name="Circle" scale={6.128}>
              <mesh
                name="Circle_0"
                castShadow
                receiveShadow
                geometry={nodes.Circle_0.geometry}
                material={materials["Material.010"]}
              />

              <group name="Icosphere002" position={[1, 0, 0]} scale={0.063}>
                <mesh
                  name="Icosphere002_0"
                  castShadow
                  receiveShadow
                  geometry={nodes.Icosphere002_0.geometry}
                  material={materials["Material.008"]}
                >
                  <meshStandardMaterial
                    emissive={"blue"}
                    emissiveIntensity={1}
                    toneMapped={false}
                  />
                </mesh>
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("models/earth_hologram.glb");
